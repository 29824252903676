<template>
  <div>
    <NuxtLink v-if="isLink" :to="map" target="blank" rel="nofollow noopener" class="transition hover:text-gray-800">
      <div
        class="inline-flex w-full items-center justify-start gap-3 text-xl underline"
        :class="{ 'gap-y-6': data.icon.data && data.text && data.subtext }">
        <ImageStrapiImage :data="data.icon.data" class="object-fit h-8 md:h-9" />
        <p class="text-md">
          {{ data.text }}
        </p>
      </div>
    </NuxtLink>

    <div
      v-else
      class="inline-flex w-full items-start justify-start gap-3 text-xl"
      :class="{
        'flex-col items-start': row,
        'md:gap-x-6': data.text && data.subtext,
        'items-center': (column && data.text && data.subtext === null) || (column && data.subtext && isDynamicHero),
        'mb-4': isDynamicHero,
      }">
      <div>
        <div
          v-if="data.icon.data && data.text && data.subtext"
          class=":md-h-12 h-10 w-10"
          :class="{ 'h-16 md:h-20 md:w-20': row }">
          <ImageStrapiImage :data="data.icon.data" class="object-fit h-8 md:h-9" :class="{ 'h-16 md:h-20': row }" />
        </div>
        <div
          v-if="data.icon.data && !data.text && data.subtext"
          class="w-6"
          :class="{
            'h-16 md:h-20': row,
            'h-6': column,
            'h-6 md:h-8': isDynamicHero,
          }">
          <ImageStrapiImage
            :data="data.icon.data"
            class="object-fit"
            :class="{
              'h-16 md:h-20': row,
              'mt-2 h-6': column,
              'h-6 md:h-8': isDynamicHero,
            }" />
        </div>

        <div
          v-if="data.icon.data && data.text && !data.subtext"
          class="h-8 w-8 md:h-9 md:w-9"
          :class="{ 'h-16 md:h-20': row }">
          <ImageStrapiImage :data="data.icon.data" class="object-fit h-8 md:h-9" :class="{ 'h-16 md:h-20': row }" />
        </div>
      </div>
      <div v-if="data.text && data.subtext" class="flex flex-col gap-1 antialiased">
        <p class="text-left text-xl leading-tight text-gray-900 md:text-3xl">
          {{ data.text }}
        </p>
        <p class="text-left text-base text-gray-900">
          {{ data.subtext }}
        </p>
      </div>
      <div v-else class="flex flex-col gap-3 text-gray-900 antialiased">
        <p v-if="data.text" class="text-left text-lg text-gray-900">
          {{ data.text }}
        </p>
        <p
          v-if="data.subtext"
          :class="{
            'text-sm md:text-lg': isDynamicHero || listItemsCardStyle,
            'text-sm': !isDynamicHero,
          }"
          class="text-left">
          {{ data.subtext }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  data: Object,
  isDynamicHero: {
    default: false,
    required: false,
    type: Boolean,
  },
  listStyle: {
    default: "",
    required: false,
    type: String,
  },
  map: {
    default: "",
    required: false,
    type: String,
  },
  orientation: {
    default: "column",
    required: false,
    type: String,
  },
  staticImageURL: String,
});

const isLink = computed(() => props.data.text === "Vorgartenstraße 206c, 1020 Wien" && props.map !== "");

const row = computed(() => props.orientation === "row");
const column = computed(() => props.orientation === "column");
const listItemsCardStyle = props.listStyle === "listItemsCard";
</script>

<style lang="scss" scoped></style>
